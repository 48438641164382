@import "../../../assets/css/variables/colors";

h1 {
  text-align: center;
}

.vacancies__wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 62px;

  &:last-child {
    margin-bottom: 124px;
    justify-content: space-evenly;

    @media (max-width: 991px) {
      margin-bottom: 60px;
    }

    @media (max-width: 550px) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 0;
  }
}

.vacancies__contents {
  display: contents;

  @media (max-width: 991px) {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    margin-top: 0;
  }
}

.vacancies__block {
  text-align: center;
  align-self: end;
  color: $white;
  font-size: 24px;
  line-height: 26px;

  img {
    margin-bottom: 20px;
  }

  &:hover {
    .vacancies__title {
     border-color: #ffffff;
    }
  }

  &--last {
    width: 189px;
    margin-right: 50px;

    @media (max-width: 991px) {
      align-self: center;
      width: 50%;
      margin: 40px 0 0;
    }

    @media (max-width: 550px) {
      margin-top: 0;
    }
  }

  @media (max-width: 991px) {
    width: 50%;
    font-size: 16px;
    padding: 15px;
  }

  @media (max-width: 550px) {
    width: 100%;
    align-self: center;
    margin-bottom: 25px;
  }
}

.vacancies__none {
  display: none;

  &--last {
    @media (max-width: 991px) {
      display: none;
    }
  }

  @media (max-width: 991px) {
    display: block;
  }
}

.vacancies__line {
  display: block;
  margin: 0 auto;
  padding: 40px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
  }
}

.vacancies__title {
  max-width: max-content;
  transition: border-color 0.3s;
  border-bottom: 2px solid transparent;
  margin: 0 auto;
}