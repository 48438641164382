@mixin flex-row($justify, $align) {
    display: flex;
    flex-direction: row;
    justify-content: $justify;
    align-items: $align;
}

@mixin flex-column($justify, $align) {
    display: flex;
    flex-direction: column;
    justify-content: $justify;
    align-items: $align;
}