@import "../../../node_modules/reset-css/reset.css";
@import "./variables/screen";
@import "./variables/main";
@import "./variables/colors";
@import "./mixins/flex";
@import "./components/container";
@import "./components/btn";
@import "./components/link";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
body {
    background: #222423;
}

* {
    font-family: "Rubik", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

h1 {
    font-size: 40px;
    line-height: 52px;
    font-weight: 500;
    color: $white;

    @media (max-width: 425px) {
        font-size: 30px !important;
        line-height: 40px !important;
    }

    @media (max-width: 375px) {
        font-size: 22px !important;
        line-height: 32px !important;
    }
}

h2 {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    color: #FF671C;

    @media (max-width: 715px) {
        padding-bottom: 20px;
    }

    @media (max-width: 425px) {
        font-size: 18px;
        line-height: 24px;
        padding: 5px 0 40px;
    }
}