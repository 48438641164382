@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";
@import "../../../assets/css/components/image";

.contacts {
  position: relative;
  background-position-x: right;

  .contacts__wrapper {
    //width: 40%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 670px) {
      flex-direction: column;
    }

    .contacts__content {
      width: 50%;

      @media (max-width: 880px) {
        width: 40%;
      }

      @media (max-width: 670px) {
        width: 49%;
      }

      @media (max-width: 670px) {
        width: 100%;
        padding: 0 0 40px;
      }

      .contacts__name {
        padding-top: 25px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        color: $primary;

        @media (max-width: 425px) {
          padding: 15px 0 10px;
          font-size: 18px;
          line-height: 24px;
        }
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      .contacts__position {
        padding-top: 5px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: $white;
      }

      a {
        display: block;
        color: $white;
        text-decoration: underline;
      }
      .contacts__mail {
        padding-top: 25px;
      }

      .contacts__phone {
        padding-top: 12px;

        @media (max-width: 670px){
          width: 100%;
        }
      }
    }

    .contacts__photo {
      height: 541px;
      width: 50%;
      position: relative;
      overflow: hidden;

      @media (max-width: 880px) {
        width: 60%;
      }

      @media (max-width: 670px) {
        width: 50%;
        height: 420px;
      }

      @media (max-width: 670px) {
        width: 100%;
      }

      .contacts__arrow {
        height: 100%;
        width: 82px;
        position: absolute;
        left: -1px;
        z-index: 5;

        @media (max-width: 670px) {
          display: none;
        }

        &--down {
          display: none;

          @media (max-width: 670px) {
            display: block;
            transform: rotate(0deg);
            width: 100%;
            top: -60%;
            left: 0;
            height: 540px;
          }

          @media (max-width: 590px) {
            top: -255px;
          }

          @media (max-width: 496px) {
            top: -256px;
          }

          @media (max-width: 464px) {
            top: -257px;
          }

          @media (max-width: 434px) {
            top: -258px;
          }

          @media (max-width: 403px) {
            top: -259px;
          }

          @media (max-width: 373px) {
            top: -260px;
          }

          @media (max-width: 343px) {
            top: -261px;
          }

          @media (max-width: 313px) {
            top: -262px;
          }

          @media (max-width: 282px) {
            top: -263px;
          }
        }
      }

      .contacts__img {
        position: absolute;
        top: 0;
        left: 1px;
        width: 750px;

        //@media (max-width: 991px) {
        //  left: -54px;
        //}

        @media (max-width: 880px) {
          width: 710px;
          //left: -111px;
        }

        @media (max-width: 670px) {
          width: 100%;
          left: 0;
        }

        @media (max-width: 550px) {
          width: 550px;
        }

        @media (max-width: 375px) {
          left: -55px;
        }
      }

    }
  }
}

