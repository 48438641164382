@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";

.advantage {
  .advantage__header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 425px) {
      justify-content: center;
    }

    h1 {
      padding-top: 40px;
      text-align: center;
    }

    img {
      @media (max-width: 425px) {
        display: none;
      }
    }
  }

  .advantage__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;

    @media (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .advantage__item {
      background-image: url("../../../assets/images/av_bg.png");
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      padding: 50px 0;
      
      text-align: center;

      @media (max-width: 768px) {
        width: 100%;
        padding: 0 0 35px 0;
        background-size: 46% 107%;
      }

      @media (max-width: 425px) {
        padding-bottom: 40px;
      }



      .advantage__desc {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: $white;
        // width: 328px;
        margin: 20px auto 0;

        @media (max-width: 991px) {
          width: 280px;
        }

        @media (max-width: 880px) {
          width: 220px;
        }

        @media (max-width: 715px) {
          width: 100%;
        }
      }
    }
  }
  .advantage__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .advantage__line {
    display: block;
    margin: 0 auto;
    padding: 40px 0;
  }
}