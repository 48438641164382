@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";
@import "../../../assets/css/components/image";

.geography {

  h1 {
    padding-top: 20px;
    text-align: center;
    font-size: 40px;
    line-height: 52px;
    font-weight: 500;
    color: $white;

    @media (max-width: 425px) {
      padding-bottom: 22px;
    }
  }

  .geography__wrapper {
    margin-top: 50px;
    padding-bottom: 40px;

    @media (max-width: 550px) {
      padding-bottom: 30px;
    }
  }

  .geography__content {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .geography__item {
      margin-right: 20px;
      padding-bottom: 35px;
      width: 25%;
      color: white;

      position: relative;



      &:hover {
        cursor: pointer;

        &::after {
          content: "";
          width: 40px;
          height: 4px;
          background-color: $white;

          position: absolute;
          bottom: 0;
          animation: geography__animate .25s;

          @keyframes geography__animate {
            0% {
              width: 0;
            }
            100% {
              width: 40px;
            }
          }
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin: 0 0 30px;
      }

      @media (max-width: 425px) {
        margin: 0 0 25px 0;
      }

      .geography__title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;

        @media (max-width: 425px) {
          font-size: 18px;
        }
      }

      .geography__desc {
        padding-top: 5px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
      }
    }

    .active {
      color: $primary !important;
      position: relative;

      &::after {
        content: "";
        width: 82px;
        height: 4px;
        background-color: $primary;

        position: absolute;
        bottom: 0;

        @media (max-width: 768px) {
          content: "";
          width: 4px;
          height: 100%;

          position: absolute;
          top: 0;
          right: 0;
        }
      }

      &:hover {
        &::after {
          content: "";
          width: 82px;
          height: 4px;
          background-color: $primary;
          animation: none;

          @media (max-width: 768px) {
            width: 4px;
            height: 100%;
          }
        }
      }
    }
  }

  .geography__map-wrapper {
    margin-top: 60px;
    height: 400px;

    @media (max-width: 425px) {
      margin-top: 15px;
    }

    .geography__map {
      height: 100%;
      width: 100%;
      ymaps {
        width: 100%;
      }
    }
  }
}