@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";

header {
    position: fixed;
    top: 0;
    left: 0;
    height: 67px;
    width: 100%;
    z-index: 10;
    background: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

    display: flex !important;
    align-items: center;

    @media (max-width: 768px) {
        height: 51px;
    }

    .nav__logo {
        height: 40px;
    }
    @media (max-width: 768px) {
        .hidden_xs {
            display: none !important;
        }
        .tools .btn {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            width: 175px;
            height: 32px;
            @include flex-row(center, center);
        }
        .nav__logo {
            height: 40px;
        }
        .nav__link {
            margin-right: 0 !important;
        }
        .menu-toggler {
            width: 16px;
            height: 14px;
            margin-right: 20px;
            @include flex-column(space-between, center);
            &:active {
                span {
                    background: $primary;
                }
            }
            span {
                display: block;
                width: 16px;
                height: 2px;
                border-radius: 4px;
                background: $white;
            }
        }
    }
}

.language__link {
    $color: $black;
    &:visited {
        color: $black;
    }
    &_active {
        color: $primary !important;
    }
}

header .container {
    width: 100%;
    @include flex-row(space-between, center);

    @media (max-width: 768px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
    .tools {
        @include flex-row(flex-end, center);
        .language {
            margin-left: 28px;
            display: flex;
            column-gap: 17px;
            position: relative;
            &:after {
                background: $black;
                height: 11px;
                width: 1px;
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    a:not(.btn) {
        font-size: 16px;
        line-height: 22px;
        color: $black;
    }
    .nav {
        @include flex-row(flex-start, center);
        .nav__link {
            margin-right: 40px;
            &.active {
                color: $primary;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .nav__submenu {
            left: 0;
            position: absolute;
            opacity: 0;
            pointer-events: none;
            top: 100%;
            padding-top: 35px;
            transition: 0.2s ease;
            .nav__link {
                margin-right: 0;
            }
            div {
                background: $white;
                min-width: 320px;
                border-radius: 8px;
                padding: 20px;
                transition: 0.2s ease;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
            }
            .nav__link {
                margin-bottom: 16px;
                &:hover {
                    color: $primary !important;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .nav__link {
            @include flex-row(flex-start, center);
            position: relative;
            color: $white;
            cursor: pointer;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            svg {
                transition: 0.2s;
            }
            &:hover {
                color: $primary;
                svg {
                    transform: rotate(180deg);
                }
                svg path {
                    fill: $primary;
                }
            }
            &_disabled {
                pointer-events: none;
                color: #afafaf !important;
            }
            &_extended {
                &:hover {
                    .nav__link {
                        color: $primary;
                    }
                    .nav__submenu {
                        opacity: 1;
                        pointer-events: all;
                        .nav__link {
                            color: $black;
                        }
                    }
                }
            }
            @media (min-width: 768px) and (max-width: 1050px) {
                font-size: 14px;
                line-height: 18px;
                margin-right: 15px;
            }
        }
        .nav__link-toggle {
            margin-left: 7px;
        }
    }
}

.menu {

    &__block {
        background-color: $white;
        height: 100%;
        width: 50%;
        opacity: 0;
        animation: menu__animation .5s forwards;

        @keyframes menu__animation {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        @media (max-width: 490px) {
            width: 100%;
        }

    }
    &_mobile {
        display: none;

        @media (max-width: 768px) {
            position: fixed;
            width: 100%;
            height: 100vh;
            background: $white;
            z-index: 15;
            top: 0;

            opacity: 0;
            animation: menu__animation .5s forwards;

            &.active {
                display: block;
                background-color: rgba(0, 0, 0, 0.8);
            }
            .menu-close {
                padding: 15px;
                position: relative;

                &__span {
                    display: block;
                    width: 2px;
                    height: 15px;
                    background: #000;
                    margin: 2px 0 0 12px;
                    border-radius: 1px;
                    position: absolute;

                    &--left {
                        transform: rotateZ(45deg);
                    }

                    &--right {
                        transform: rotateZ(-45deg);
                    }
                }
            }
            nav {
                position: relative;
                @include flex-column(flex-start, flex-start);
                padding: 105px 20px 0;
                .nav__link {
                    width: 100%;
                    @include flex-row(space-between, center);
                    font-size: 16px;
                    line-height: 20px;
                    color: $black;
                    padding-bottom: 25px;

                    &_active {
                        color: $primary
                    }
                    &_disabled {
                        pointer-events: none;
                        color: #afafaf !important;
                    }
                }
                .nav__submenu {
                    max-height: 0;
                    opacity: 0;
                    padding-left: 24px;
                    pointer-events: none;
                    transition: .3s;
                    .nav__link {
                        font-weight: 400;
                    }
                    &.active {
                        max-height: 310px;
                        opacity: 1;
                        pointer-events: all;
                        transition: .3s;
                        padding-bottom: 8px;
                    }
                }
            }
        }
        @media (max-width: 768px) {}
    }
}

@media (max-width: 1050px) {
    .menu-opened {
        pointer-events: none;
        overflow-y: hidden;
        .menu.active {
            pointer-events: all;
            overflow-y: auto;
        }
    }
}
