@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";

.main {
  display: flex;
  align-items: center;
  margin-top: 67px;
  //width: 100%;
  height: 540px;
  background-image: linear-gradient(180deg, rgba(34, 36, 35, 0.06) 4.91%, #222423 100%),
                    url('../../../assets/images/about_2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    margin-top: 51px;
  }

  h1 {
    width: 500px;
    text-align: center;

    @media (max-width: 540px) {
      width: auto;
    }
  }

  .main__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn {
    margin-top: 40px;
  }

  img {
    margin-top: 25px;
  }
}