@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";

.about {
    padding-top: 40px;
    background-color: $white;
    img {
        display: block;
        width: 100%;

        @media (max-width: 500px) {
            width: 100% !important;
        }
    }
    h1 {
        display: inline;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #222423;
        padding-bottom: 12px;

        border-bottom: 4px solid $primary;

        @media (max-width: 425px) {
            font-size: 22px !important;
            padding-bottom: 0;
        }
    }

    .about__wrapper {
        margin-bottom: 20px;
        display: flex;

        @media (max-width: 1024px) {
            margin-bottom: 40px;
            flex-direction: column;
        }

        .about__item {
            width: 50%;
            @media (max-width: 1024px) {
                width: 100%;
            }


            .about__title {
                margin-top: 20px;
                font-size: 40px;
                font-weight: 500;
                line-height: 52px;
                color: #222423;
            }

            img {
                margin-left: 40px;
                width: 450px;

                @media (max-width: 1100px) {
                    margin-left: 0;
                }

                @media (max-width: 1024px) {
                    margin: 25px auto 0;
                }
            }

            .about__desc {
                margin-top: 20px;
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                color: #222423;

                @media (max-width: 425px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .about__text {
                margin: 25px 0 45px;
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                color: #222423;
            }

            .about__down {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                @media (max-width: 500px) {
                    justify-content: center;
                }
            }

            .about__rectangle {
                width: 50%;
                height: 20px;
                background: #F8F8F8;

                @media (max-width: 500px) {
                    display: none;
                }
            }

        }
    }
}
